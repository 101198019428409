import React from 'react'
import Layout from '../components/layout'

export default function PageNotFound() {
  return (

    <Layout>
      <h1> Page not Found </h1>
    </Layout>
  )

}
